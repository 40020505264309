import React from 'react';
import PropTypes from 'prop-types';

import { Cover, Image, Layout, SectionOurStory, SectionPress, SectionTextMedia, SEO } from 'components';
import { routes } from 'utils/routes';

import content from './about.yml';

const AboutPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Cover
      title='Europe’s most trusted online aligner solution'
      image={{ url: 'pages/about_cover.jpg', alt: 'About Straight Teeth Direct™' }}
      button={{ label: 'Get started', ...routes.getStarted }}
    />

    <SectionTextMedia
      title='Our mission is to make orthodontics more accessible and affordable through technology.'
      text=''
      image={{ url: 'pages/about_one.jpg', alt: 'Our Mission' }}
      className='bg-img-dotted'
    />

    <SectionTextMedia
      title='Created by dentists.<br/>Powered by technology.'
      text="We take teeth seriously. That’s why Straight Teeth Direct&trade; is powered by expert dentists and the latest technology. Our dentists and orthodontists are hand-picked because they want to change the face of dentistry. They use digital innovation to help more people benefit from their knowledge and work together to create breakthroughs in tele-orthodontics."
      image={{ url: 'pages/about_two.jpg', alt: 'Created by dentists, powered by technology' }}
      reverse
      className='small-media'
    />

    <SectionTextMedia
      title='The science behind the smiles'
      text="Made in the UK, with offices across Europe and delivered straight to your door all around the world. With a multicultural team, users in over 50 countries and 5 continents we are a truly global brand.<br/><br/>We have rethought and redesigned the entire aligner manufacturing process to optimise faster tooth movement completely remotely without clinic visits and worked on hardware innovations to create aligners that are superiorly comfortable to ensure the very best quality and results for your smile."
      image={{ url: 'pages/about_three.jpg', alt: 'The science behind Straight Teeth Direct™' }}
      // videoUrl='https://straight-teeth-direct.wistia.com/medias/3x3cqkadep'
      videoUrl='/about_science_behind.mp4'
      isVideo
    />

    <SectionOurStory />

    <div className='section-founders'>
      <Image url='pages/about_founders.jpg' alt='Straight Teeth Direct™ Founders' />
      <div className='flex fw'>
        <div className='founder-wrapper'>
          <h3>Dr Aalok Y Shukla</h3>
          <p>Co-founder & CEO</p>
        </div>
        <div className='founder-wrapper'>
          <h3>Lucie Marchelot Shukla</h3>
          <p>Co-Founder & Managing Director</p>
        </div>
      </div>
    </div>

    <div className='section-dna'>
      <div className='dna-content'>
        <h2 className='mb-20'>Our DNA</h2>
        <p>Fully digitised and mobile end-to-end experience enabled by our 5 pillars of innovation.</p>
      </div>
      <div className='title-dna circle'>Industry expertise</div>
      <div className='title-dna triangle'>3D printing</div>
      <div className='title-dna losange'>Data cloud</div>
      <div className='title-dna hexagon'><div className='hexagon-shape' />Active products</div>
      <div className='title-dna square'>Smart devices</div>
    </div>

    <h2 className='section-title mb-20'>Interactive instore experience</h2>
    <h2 className='section-title smartech-logo mb-50'>Now available at <Image url='logos/logo_smartech.png' alt='Smart tech' /> in:</h2>
    <div className='section-wrapper small-media'>
      <div className='section-text stores-wrapper'>
        <h2 className='section-title'>London<br/>—<br/>Selfridges</h2>
      </div>
      <Image url='pages/home_smartech.jpg' alt='Showcase of straight teeth direct products' className='section-media' />
    </div>

    <h2 className='section-title mb-50'>As seen in</h2>
    <SectionPress />

    <div className='section-milestones bg-gray-dotted'>
      <h2 className='section-title'>Milestones</h2>
      <div className='section-logos'>
        <Image url='logos/milestone_foundersfactory.png' alt='Founders Factory - Best AI Business 2018' />
        <Image url='logos/milestone_futurehealthcare.png' alt='Future Healthcare - Best Innovator 2018' />
        <Image url='logos/milestone_eit.png' alt='EIT Health logo' />
        <Image url='logos/milestone_europas.png' alt='EUROPAS - Hottest Healthtech Startup 2019' />
      </div>
    </div>
  </Layout>
);

AboutPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutPage;
